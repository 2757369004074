import numeral from 'numeral'

let formatNumber = (number) => {
  if (isNaN(number))
    return 0
  if (number) {
    if (Math.abs(number) > 1000)
      return numeral(number).format('0a')
    else 
    return numeral(number).format('0,0')
  }
  else return number
}

let formatNumberVerbose = (number) => {
  if (number > 0) {
    return formatNumber(number).replace('m', ' Million').replace('b', ' Billion').replace('k', 'K')
  }
}

let formatFloat = (number) => {
  if (isNaN(number))
    return 0
  return numeral(number).format('0.00')
}

let toCamelCase = (string) => {
	let inputArray = string.split(' ')
	let result = "";
	for(let i = 0 , len = inputArray.length; i < len; i++) {
		let currentStr = inputArray[i];
		let tempStr = currentStr.toLowerCase();
		if(i != 0) {
				tempStr = tempStr.substr(0, 1).toUpperCase() + tempStr.substr(1);
		 }
		 result += tempStr;	
	}
	return result;
}

let toKebabCase = (string) => {
	return string && string
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toLowerCase())
    .join('-')
}

let formatCurrency = (number) => {
  if (Math.abs(number) > 1000)
    return numeral(number).format('$0,0.00a')
  else
    return numeral(number).format('$0.00')
}

let capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
	formatNumber,
	formatNumberVerbose,
	formatFloat,
	toCamelCase,
	toKebabCase,
	formatCurrency,
	capitalizeFirstLetter
}